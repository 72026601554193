export const HelloMsg = () => {
  return (
    <svg
      id="logo"
      viewBox="0 0 676 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M80.824 7.864V97.72H58.936V60.728H24.888V97.72H3V7.864H24.888V43.064H58.936V7.864H80.824Z"
        stroke="#0F97B8"
        strokeWidth="5"
      />
      <path
        d="M167.793 60.856C167.793 62.904 167.665 65.0373 167.409 67.256H117.873C118.214 71.6933 119.622 75.1067 122.097 77.496C124.657 79.8 127.772 80.952 131.441 80.952C136.902 80.952 140.7 78.648 142.833 74.04H166.129C164.934 78.7333 162.758 82.9573 159.601 86.712C156.529 90.4667 152.646 93.4107 147.953 95.544C143.26 97.6773 138.012 98.744 132.209 98.744C125.212 98.744 118.982 97.2507 113.521 94.264C108.06 91.2773 103.793 87.0107 100.721 81.464C97.649 75.9173 96.113 69.432 96.113 62.008C96.113 54.584 97.6063 48.0987 100.593 42.552C103.665 37.0053 107.932 32.7387 113.393 29.752C118.854 26.7653 125.126 25.272 132.209 25.272C139.121 25.272 145.265 26.7227 150.641 29.624C156.017 32.5253 160.198 36.664 163.185 42.04C166.257 47.416 167.793 53.688 167.793 60.856ZM145.393 55.096C145.393 51.3413 144.113 48.3547 141.553 46.136C138.993 43.9173 135.793 42.808 131.953 42.808C128.284 42.808 125.169 43.8747 122.609 46.008C120.134 48.1413 118.598 51.1707 118.001 55.096H145.393Z"
        stroke="#0F97B8"
        strokeWidth="5"
      />
      <path
        d="M205.068 3V97.72H183.18V3H205.068Z"
        stroke="#0F97B8"
        strokeWidth="5"
      />
      <path
        d="M246.658 3V97.72H224.77V3H246.658Z"
        stroke="#0F97B8"
        strokeWidth="5"
      />
      <path
        d="M298.872 98.744C291.875 98.744 285.56 97.2507 279.928 94.264C274.381 91.2773 269.987 87.0107 266.744 81.464C263.587 75.9173 262.008 69.432 262.008 62.008C262.008 54.6693 263.629 48.2267 266.872 42.68C270.115 37.048 274.552 32.7387 280.184 29.752C285.816 26.7653 292.131 25.272 299.128 25.272C306.125 25.272 312.44 26.7653 318.072 29.752C323.704 32.7387 328.141 37.048 331.384 42.68C334.627 48.2267 336.248 54.6693 336.248 62.008C336.248 69.3467 334.584 75.832 331.256 81.464C328.013 87.0107 323.533 91.2773 317.816 94.264C312.184 97.2507 305.869 98.744 298.872 98.744ZM298.872 79.8C303.053 79.8 306.595 78.264 309.496 75.192C312.483 72.12 313.976 67.7253 313.976 62.008C313.976 56.2907 312.525 51.896 309.624 48.824C306.808 45.752 303.309 44.216 299.128 44.216C294.861 44.216 291.32 45.752 288.504 48.824C285.688 51.8107 284.28 56.2053 284.28 62.008C284.28 67.7253 285.645 72.12 288.376 75.192C291.192 78.264 294.691 79.8 298.872 79.8Z"
        stroke="#0F97B8"
        strokeWidth="5"
      />
      <path
        d="M455.625 26.296L410.825 131.64H387.273L403.657 95.288L374.601 26.296H399.049L415.561 70.968L431.945 26.296H455.625Z"
        stroke="#0F97B8"
        strokeWidth="5"
      />
      <path
        d="M499.892 98.744C492.895 98.744 486.58 97.2507 480.948 94.264C475.401 91.2773 471.007 87.0107 467.764 81.464C464.607 75.9173 463.028 69.432 463.028 62.008C463.028 54.6693 464.649 48.2267 467.892 42.68C471.135 37.048 475.572 32.7387 481.204 29.752C486.836 26.7653 493.151 25.272 500.148 25.272C507.145 25.272 513.46 26.7653 519.092 29.752C524.724 32.7387 529.161 37.048 532.404 42.68C535.647 48.2267 537.268 54.6693 537.268 62.008C537.268 69.3467 535.604 75.832 532.276 81.464C529.033 87.0107 524.553 91.2773 518.836 94.264C513.204 97.2507 506.889 98.744 499.892 98.744ZM499.892 79.8C504.073 79.8 507.615 78.264 510.516 75.192C513.503 72.12 514.996 67.7253 514.996 62.008C514.996 56.2907 513.545 51.896 510.644 48.824C507.828 45.752 504.329 44.216 500.148 44.216C495.881 44.216 492.34 45.752 489.524 48.824C486.708 51.8107 485.3 56.2053 485.3 62.008C485.3 67.7253 486.665 72.12 489.396 75.192C492.212 78.264 495.711 79.8 499.892 79.8Z"
        stroke="#0F97B8"
        strokeWidth="5"
      />
      <path
        d="M623.248 26.296V97.72H601.36V87.992C599.141 91.1493 596.112 93.7093 592.272 95.672C588.517 97.5493 584.336 98.488 579.728 98.488C574.267 98.488 569.445 97.2933 565.264 94.904C561.083 92.4293 557.84 88.888 555.536 84.28C553.232 79.672 552.08 74.2533 552.08 68.024V26.296H573.84V65.08C573.84 69.8587 575.077 73.5707 577.552 76.216C580.027 78.8613 583.355 80.184 587.536 80.184C591.803 80.184 595.173 78.8613 597.648 76.216C600.123 73.5707 601.36 69.8587 601.36 65.08V26.296H623.248Z"
        stroke="#0F97B8"
        strokeWidth="5"
      />
      <path
        d="M671.61 4.66399L669.05 65.976H650.49L647.93 4.66399H671.61ZM660.09 98.744C656.25 98.744 653.093 97.6347 650.618 95.416C648.229 93.112 647.034 90.296 647.034 86.968C647.034 83.5547 648.229 80.696 650.618 78.392C653.093 76.088 656.25 74.936 660.09 74.936C663.845 74.936 666.917 76.088 669.306 78.392C671.781 80.696 673.018 83.5547 673.018 86.968C673.018 90.296 671.781 93.112 669.306 95.416C666.917 97.6347 663.845 98.744 660.09 98.744Z"
        stroke="#0F97B8"
        strokeWidth="5"
      />
    </svg>
  );
};
