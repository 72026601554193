import React from "react";

function Footer() {
  return (
    <footer>
      <p className="footer-text">Nikola Golubovic, Web Developer, Serbia</p>
    </footer>
  );
}

export default Footer;
